import React from 'react';

const Title = () => {
  return (
    <div className="title">
      <h1>
      We take photos as a return ticket to a moment
      <span role='img' aria-label='photo'> 📸 </span>
      </h1>
      <h2>
        Rotate your mobile device to landscape position for better photo view !
      </h2>
    </div>
  )
}

export default Title;